<template>
  <sm-editable-item
    v-model="form"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :controllerName="controllerName"
    :disabledSaveButton="isEqual"
    @save="onSave"
    @cancel="onCancel"
  />
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'AccountForCustomerEdit',

  components: {
    SmEditableItem,
  },

  data() {
    return {
      isLoadingPage: false,
      parentName: 'Customers',
      controllerName: 'Accounts',
      pageHeader: 'Редактирование аккаунта',
      parentItem: null,
      initialForm: null,
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      startPages: (state) => state.common.startPages,
    }),

    id() {
      return +this.$route.params.id;
    },

    parentId() {
      return +this.$route.params.parentId;
    },

    isEqual() {
      return this.lodash.isEqual(this.form, this.initialForm);
    },

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты',
          route: { name: 'Customers' },
        },
        {
          text: `Редактирование клиента ${this.parentItem?.name}`,
          route: { name: 'CustomerEdit', params: { id: this.parentId } },
        },
        {
          text: 'Аккаунты',
          route: { name: 'AccountsForCustomer', params: { id: this.parentId } },
        },
        {
          text: 'Редактирование аккаунта',
        },
      ];
    },

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'fio',
              label: 'ФИО',
            },
            {
              type: 'text',
              key: 'login',
              label: 'Логин (телефон)',
            },
            {
              type: 'text',
              key: 'phone',
              label: 'Дополнительные телефоны (заполнять через ;)',
            },
            {
              type: 'text',
              key: 'password',
              label: 'Пароль',
            },
            {
              type: 'text',
              key: 'mail',
              label: 'Email',
            },
            {
              type: 'date',
              key: 'created',
              label: 'Создан',
            },
            {
              type: 'text',
              key: 'position',
              label: 'Должность',
            },
            {
              type: 'checkbox',
              label: 'Контактное лицо по нашим услугам',
              key: 'isContactPersone',
            },
            {
              type: 'checkbox',
              label: 'Контактное лицо по финансовым вопросам',
              key: 'isContactFinancePersone',
            },
            {
              type: 'checkbox',
              label: 'Архивный',
              key: 'isArchive',
            },
            {
              type: 'text',
              key: 'comment',
              label: 'Комментарий',
            },
            {
              type: 'select',
              key: 'startPageId',
              label: 'Стартовая страница',
              list: this.startPages.data,
            },
            {
              type: 'checkbox',
              key: 'isUseChatBot',
              label: 'Использовать чат-бота WhatsApp',
            },
          ],
        },
        {
          tab: 'Параметры для сервиса UIS',
          form: [
            {
              type: 'text',
              key: 'uisToken',
              label: 'Ключ (генерируется в личном кабинете UIS)',
            },
            {
              type: 'text',
              key: 'uisVirtualPhone',
              label: 'Виртуальный номер, арендуемый клиентом',
            },
            // {
            //   type: 'text',
            //   key: 'uisOperatorPhone',
            //   label: '',
            // },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const parentItem = this.getItem({
      name: this.parentName,
      id: this.parentId,
    }).then((result) => {
      this.parentItem = this.lodash.cloneDeep(result);
    });

    const item = this.getItem({ name: this.controllerName, id: this.id }).then(
      (result) => {
        this.initialForm = this.lodash.cloneDeep(result);
        this.form = this.lodash.cloneDeep(result);
      }
    );

    const startPages = this.getCommonList({ name: 'StartPages' });

    Promise.all([parentItem, item, startPages]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),

    onSave() {
      // this.$root
      //   .$confirmModal({
      //     message: 'Сохранить изменения?',
      //     leftButtonText: 'Сохранить',
      //     rightButtonText: 'Отменить',
      //   })
      //   .then((response) => {
      //     if (response) {
      this.isLoadingSaveButton = true;
      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      }).then((result) => {
        if (result.isSucceed) {
          this.$router.push({
            name: 'AccountsForCustomer',
            params: { id: this.parentId },
          });
        }
      });
      //   }
      // });
    },

    onCancel() {
      if (this.isEqual) {
        this.$router.push({
          name: 'AccountsForCustomer',
          params: { id: this.parentId },
        });
        return;
      }

      this.$root
        .$confirmModal({
          message: 'Отменить редактирование?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.$router.push({
              name: 'AccountsForCustomer',
              params: { id: this.parentId },
            });
          }
        });
    },
  },
};
</script>
